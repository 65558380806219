import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box, CardMedia } from "@mui/material";
import Step0Image from "../../assets/step0.png";
import Step1Image from "../../assets/step1.png";
import Step2Image from "../../assets/step2.jpeg";
import Step3Image from "../../assets/step3.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Gmail2FATutorial: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />

      <Container
        maxWidth="md"
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: "#f0f0f0",
          minHeight: "100vh",
        }}
      >
        <Button
          variant="text"
          onClick={() => navigate(-1)}
          sx={{ mb: 2, textTransform: "none", color: "#10A5D1" }}
        >
          ← Volver a Tutorials
        </Button>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            mb: 4,
            textAlign: "center",
          }}
        >
          Cómo activar la verificación en 2 pasos en Gmail
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          La verificación en 2 pasos es una medida de seguridad que protege tu
          cuenta de accesos no autorizados. Sigue estos pasos para activarla en
          tu cuenta de Gmail:
        </Typography>

        {/* Paso 1 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 1: Inicia sesión en tu cuenta de Google
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Ve a la página de configuración de tu cuenta de Google
            (myaccount.google.com) e inicia sesión si no lo has hecho.
          </Typography>
          <CardMedia
            component="img"
            image={Step0Image}
            alt="Pantalla de inicio de sesión en Google"
            sx={{
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              mb: 2,
            }}
          />
        </Box>

        {/* Paso 2 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 2: Abre la sección de Seguridad
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            En el menú lateral, selecciona "Seguridad" y desplázate hacia abajo
            hasta la sección "Iniciar sesión en Google".
          </Typography>
          <CardMedia
            component="img"
            image={Step1Image}
            alt="Sección de Seguridad en Google"
            sx={{
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              mb: 2,
            }}
          />
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 3: Activa la verificación en 2 pasos
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Haz clic en "Verificación en dos pasos" y sigue las instrucciones.
            Tendrás que verificar tu identidad mediante un código enviado a tu
            teléfono.
          </Typography>
          <CardMedia
            component="img"
            image={Step2Image}
            alt="Sección de Seguridad en Google"
            sx={{
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              mb: 2,
            }}
          />
        </Box>

        {/* Paso 4 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 4: Configura métodos adicionales
          </Typography>
          <CardMedia
            component="img"
            image={Step3Image}
            alt="Sección de Seguridad en Google"
            sx={{
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              mb: 2,
            }}
          />
          <Typography variant="body2" sx={{ mb: 3 }}>
            Una vez activada, puedes configurar métodos adicionales de
            verificación, como aplicaciones de autenticación o claves de
            seguridad físicas.
          </Typography>
        </Box>

        <Typography variant="body1" sx={{ mt: 4, fontWeight: "bold" }}>
          Nota: La verificación en 2 pasos añade una capa adicional de seguridad
          y protege tu cuenta contra accesos no autorizados.
        </Typography>
      </Container>
      <Box sx={{ height: "100px", backgroundColor: "#f9f9f9" }} />

      <Footer />
    </div>
  );
};

export default Gmail2FATutorial;
