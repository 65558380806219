import React, { useState, useEffect } from "react";
import { IconButton, Box, Typography, Button, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface PomodoroTimerProps {
  onClose: () => void;
}

const PomodoroTimer: React.FC<PomodoroTimerProps> = ({ onClose }) => {
  const [time, setTime] = useState(1500); 
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedDuration, setSelectedDuration] = useState(1500);

  const startTimer = (duration: number) => {
    setSelectedDuration(duration);
    setTime(duration);
    setIsActive(true);
    setMessage("");
  };

  const pauseTimer = () => {
    setIsActive(false);
  };

  const resetTimer = () => {
    setTime(selectedDuration);
    setIsActive(false);
    setMessage("");
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isActive && time > 0) {
      timer = setInterval(() => {
        setTime((prev) => prev - 1);
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
      setMessage("¡Excelente trabajo! Has completado una sesión.");
      // Sonido de finalización si se necesita (remplaza 'audio.play()' con la ruta adecuada)
    }
    return () => clearInterval(timer);
  }, [isActive, time]);

  return (
    <Box
      sx={{
        position: "relative",
        padding: { xs: 3, sm: 4 },
        backgroundColor: "white",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "90%", sm: "80%", md: "400px" },
        maxWidth: "100%",
        boxShadow: 3,
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "grey.600",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h5" fontWeight="bold" textAlign="center" mb={1}>
        Temporizador Pomodoro
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        mb={4}
        sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
      >
        ¡Concéntrate en tus metas! 🎯
      </Typography>

      <Box
        sx={{
          width: { xs: "150px", sm: "200px" },
          height: { xs: "150px", sm: "200px" },
          borderRadius: "50%",
          backgroundColor: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 3,
          boxShadow: 2,
        }}
      >
        <Typography
          variant="h3"
          component="span"
          fontWeight="bold"
          color="white"
          sx={{ fontSize: { xs: "2.5rem", sm: "3rem" } }}
        >
          {`${Math.floor(time / 60)
            .toString()
            .padStart(2, "0")}:${(time % 60).toString().padStart(2, "0")}`}
        </Typography>
      </Box>

      <Stack
        spacing={1}
        justifyContent="center"
        sx={{
          mb: 2,
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={1}
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => startTimer(1500)}
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            25 MIN
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => startTimer(1800)}
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            30 MIN
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => startTimer(2700)}
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            45 MIN
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => startTimer(3600)}
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            1 HORA
          </Button>
        </Box>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="warning"
          onClick={pauseTimer}
          sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
        >
          PAUSAR
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={resetTimer}
          sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
        >
          REINICIAR
        </Button>
      </Stack>

      {message && (
        <Typography
          variant="body1"
          color="success.main"
          textAlign="center"
          mt={2}
          fontWeight="medium"
          sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default PomodoroTimer;
