import React from "react";
import { useLanguageStore } from "../components/utils/LanguageStore";

import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const QuestionsAccordion = () => {
  const { t } = useLanguageStore();

  // Obtener el valor traducido y validarlo
  const faqs = t("faq.questions");
  const parsedFaqs =
    Array.isArray(faqs) && faqs.every((faq) => typeof faq.question === "string" && typeof faq.answer === "string")
      ? (faqs as { question: string; answer: string }[])
      : [];

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 1,
          paddingBottom: -5,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop:"125px",
            color: "#0D47A1",
          }}
        >
          {t("faq.title")}
        </Typography>
        <Box my={10}>
          {parsedFaqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                mb: 2,
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "#0D47A1",
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#37474F",
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default QuestionsAccordion;
