import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import HomeScreen from "./screens/HomeScreen";
import EducacionScreen from "./screens/EducationScreen";
import ArticulosScreen from "./screens/ArticlesScreen";
import PomodoroTimer from "./components/PomodoroTimer";
import ContactScreen from "./screens/ContactScreen";
import ToolsScreen from "./screens/ToolsScreen";
import TutorialsScreen from "./screens/TutorialsScreen";
import InstagramUsageTutorial from "./screens/subscreens/InstagramUsageTutorial";
import Gmail2FATutorial from "./screens/subscreens/Gmail2FATutorial";

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/nosotros" element={<HomeScreen />} />
          <Route path="/educacion" element={<EducacionScreen />} />
          <Route path="/articulos" element={<ArticulosScreen />} />
          <Route path="/contacto" element={<ContactScreen />} />
          <Route path="/herramientas" element={<ToolsScreen />} />
          <Route path="/tools/tutorials" element={<TutorialsScreen />} />
          <Route
            path="/tutorials/instagram-usage"
            element={<InstagramUsageTutorial />}
          />
          <Route path="/tutorials/gmail-2fa" element={<Gmail2FATutorial />} />
          <Route
            path="/pomodoro"
            element={
              <PomodoroTimer onClose={() => console.log("Pomodoro closed")} />
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
