import React from "react";
import { Box, Typography, Container, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PodcastsIcon from "@mui/icons-material/Podcasts";

const Footer: React.FC = () => {
  return (
    <Box bgcolor="#1871b1" color="white" py={3}>
      <Container maxWidth="md">
        <Typography variant="body2" align="center">
          Conciencia Digital. Derechos Reservados. 2024 Made in by &copy;{" "}
          <a
            href="https://gaunetcreators.com/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Gaunet
          </a>
        </Typography>

        <Box textAlign="center" mt={2}>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/concienciadigital/"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.youtube.com/watch?v=GSn2oU3lHhk"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <PodcastsIcon />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
