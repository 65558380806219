import React, { useState } from "react";
import { Container, Typography, Box, TextField } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GridComponent from "../components/GridComponent";
import QuoteComponent from "../components/QuoteComponent";
import { useQuery } from "@tanstack/react-query";
import { useLanguageStore } from "../components/utils/LanguageStore";

const articleData1 = [
  {
    title: "Nomofobia, la nueva adicción al móvil.",
    description:
      "¿Estamos realmente conectados o solo “scrolleando” sin parar?",
    link: "https://www.youtube.com/watch?v=p3McdLVRWqY",
    image: "https://pbs.twimg.com/media/GawG_lWbMAAvsdi.jpg:large",
  },
  {
    title: "Basta de pantallas... 📵",
    description:
      "video informativo en el canal de Youtube de la plataforma Platzi.",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7277280615584976897",
    image: "https://i.ytimg.com/vi/zE47z_CsWsk/maxresdefault.jpg",
  },
  {
    title: "InfoSecMap",
    description:
      "Anuncios de Eventos y grupos de Ciberseguridad a nivel Internacional.",
    link: "https://infosecmap.com/",
    image:
      "https://infosecmap.com/wp-content/uploads/2020/11/InfoSecMap-round.png",
  },
];

const articleData2 = [
  {
    title: "ITAA: Asociación para adictos al Inet y Tecnología.",
    description: "Adictos a Internet y la Tecnología Anónimos.",
    link: "https://internetaddictsanonymous.org/es/espanol/",
    image:
      "https://internetaddictsanonymous.org/wp-content/uploads/2023/01/cropped-ITAA-logo-480px.png",
  },
  {
    title: "Keylogging Attack",
    description:
      "Keylogging es conocida como Acoustic Keyboard Eavesdropping Keylogging Attack (Ataque de espionaje a través del teclado acústico).",
    link: "https://www.infobae.com/economia/2024/08/08/keylogging-como-funciona-la-nueva-amenaza-virtual-que-vacia-cuentas-bancarias-con-solo-tocar-una-tecla/",
    image:
      "https://www.redeszone.net/app/uploads-redeszone.net/2022/01/keylogger.jpg",
  },
  {
    title: "Suecia: limita el uso de teléfonos móviles en las escuelas.",
    description: "Credits: Infobae.",
    link: "https://www.infobae.com/tecno/2024/11/27/suecia-en-polemica-mundial-limita-el-uso-de-telefonos-moviles-en-las-escuelas-y-abre-la-puerta-a-los-libros/",
    image:
      "https://www.travel-xperience.com/sites/default/files/suecia_accesible_silla_de_ruedas.jpg",
  },
];

const fetchArticles = async (): Promise<
  { title: string; description: string; link: string; image: string }[]
> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([...articleData1, ...articleData2]);
    }, 1000); // Simula 1 segundo de delay
  });
};

const ArticlesScreen: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Obtener función de traducción desde zustand
  const { t } = useLanguageStore();

  const {
    data: articles,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["articles"],
    queryFn: fetchArticles,
    staleTime: 1000,
    placeholderData: [],
  });

  const filteredArticles = articles?.filter(
    (article) =>
      article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return <p>{t("articles.loading")}</p>;
  if (error) return <p>{t("articles.error")}</p>;

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: {
              xs: "1.5rem",
              sm: "2rem",
              md: "2.5rem",
              lg: "3rem",
              xl: "3.5rem",
            },
            textAlign: "center",
          }}
        >
          {t("articles.title")}
        </Typography>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <TextField
            label={t("articles.searchPlaceholder")}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: "70%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px", // Bordes redondeados
                backgroundColor: "#ffffff", // Fondo blanco
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Sombra suave
                "& fieldset": {
                  borderColor: "#cccccc", // Color del borde inicial
                },
                "&:hover fieldset": {
                  borderColor: "#10A5D1", // Color del borde al pasar el cursor
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#10A5D1", // Color del borde al hacer foco
                  boxShadow: "0 0 8px rgba(16, 165, 209, 0.5)", // Sombra adicional en foco
                },
              },
              "& .MuiInputLabel-root": {
                color: "#666666", // Color del label
                "&.Mui-focused": {
                  color: "#10A5D1", // Color del label al hacer foco
                },
              },
              "& .MuiInputBase-input": {
                padding: "12px 16px", // Ajusta el padding interno
              },
            }}
            InputProps={{
              startAdornment: (
                <Box sx={{ marginRight: "8px", color: "#10A5D1" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35M10.5 19a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
                    />
                  </svg>
                </Box>
              ),
            }}
          />
        </Box>

        <Box sx={{ width: "100%", padding: "16px 0" }}>
          <GridComponent cardData={filteredArticles ?? []} />
        </Box>

        {filteredArticles && filteredArticles.length === 0 && (
          <Typography variant="h6" color="textSecondary" sx={{ marginTop: 2 }}>
            {t("articles.noResults")}
          </Typography>
        )}

        <QuoteComponent
          quote={t("articles.privacyQuote")}
          author={t("articles.privacyAuthor")}
        />
      </Container>
      <Box sx={{ height: "100px", backgroundColor: "#f0f0f0" }} />
      <Footer />
    </div>
  );
};

export default ArticlesScreen;
