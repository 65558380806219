import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StepIntroIG from "../../assets/stepIntroIG.jpg";
import Step0IG from "../../assets/step0IG.jpg";
import Step2IG from "../../assets/step2IG.jpg";
import profileIG from "../../assets/profileIG.png";
import LimiteIG from "../../assets/limiteIG.png";

const InstagramUsageTutorial: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: "#f0f0f0",
          minHeight: "100vh",
        }}
      >
        <Button
          variant="text"
          onClick={() => navigate(-1)}
          sx={{ mb: 2, textTransform: "none", color: "#10A5D1" }}
        >
          ← Volver a Tutorials
        </Button>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            mb: 4,
            textAlign: "center",
          }}
        >
          Cómo medir tu consumo de Instagram
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Instagram es una aplicación que puede consumir gran parte de nuestro
          tiempo si no se gestiona adecuadamente. A continuación, te mostramos
          cómo puedes medir y gestionar tu tiempo en la plataforma:
        </Typography>

        {/* Imagen introductoria */}
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img
            src={StepIntroIG}
            alt="Introducción a medir el consumo de Instagram"
            style={{
              borderRadius: "8px",
              maxWidth: "50%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Box>

        {/* Paso 1 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 1: Accede a tu perfil de usuario
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Haz clic en el icono de tu perfil en la esquina inferior derecha de
            la aplicación para abrir tu perfil.
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={profileIG}
              alt="Paso 1: Accede a tu perfil"
              style={{
                borderRadius: "8px",
                maxWidth: "50%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        </Box>

        {/* Paso 2 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 2: Abre la sección de "Tu actividad"
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Desde tu perfil, toca el menú en la esquina superior derecha (tres
            líneas horizontales) y selecciona "Tu actividad" en la lista.
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={Step2IG}
              alt="Paso 2: Abre la sección de 'Tu actividad'"
              style={{
                borderRadius: "8px",
                maxWidth: "50%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        </Box>

        {/* Paso 3 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 3: Revisa el tiempo de uso
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            En "Tu actividad", podrás ver el tiempo promedio diario que pasas en
            Instagram. Aquí también puedes establecer recordatorios para limitar
            tu tiempo de uso diario.
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={Step0IG}
              alt="Paso 2: Abre la sección de 'Tu actividad'"
              style={{
                borderRadius: "8px",
                maxWidth: "50%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        </Box>

        {/* Paso 4 */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Paso 4: Establece un límite de tiempo
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Selecciona "Establecer recordatorio diario" y elige cuánto tiempo
            deseas pasar en Instagram al día. La aplicación te notificará cuando
            hayas alcanzado este límite.
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <img
            src={LimiteIG}
            alt="Paso final:recordatorio"
            style={{
              borderRadius: "8px",
              maxWidth: "50%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Box>
        <Typography variant="body1" sx={{ mt: 4, fontWeight: "bold", alignItems:"center" }}>
          Recuerda: Gestionar tu tiempo en redes sociales puede mejorar tu
          productividad y bienestar.
        </Typography>
      </Container>
      <Box sx={{ height: "100px", backgroundColor: "#f9f9f9" }} />

      <Footer />
    </div>
  );
};

export default InstagramUsageTutorial;
