const translations = {
  en: {
    home: {
      welcomeMessage:
        "Platform of resources aimed at promoting and elevating digital awareness.",
      aboutUsTitle: "About Us",
      aboutUsMessage:
        "Welcome to Conciencia Digital. We promote safe technology use and empower individuals with tools and knowledge to protect themselves from cybercrime risks like cyberbullying and grooming, and to navigate the digital world safely and consciously.",
      initiatives: "Join us and follow our initiatives on social media:",
      hashtags:
        "#AntiCyberBullying #FightAgainstGrooming #AntiDigitalScams #EthicalHackers",
      welcomeButton: "See More",
      supportButton: "Support Us",
    },
    education: {
      title: "Educational Resources",
      searchPlaceholder: "Search educational resources",
      loading: "Loading resources...",
      error: "Error loading educational resources",
      noResults: "No resources found.",
      digitalBreakMessage:
        "Take a breath and improve your focus with our Digital Break Timer.",
      digitalBreakCTA: "I want a break",
    },
    articles: {
      title: "Informative Articles",
      searchPlaceholder: "Search articles",
      loading: "Loading articles...",
      error: "Error loading articles",
      noResults: "No articles found.",
      privacyQuote:
        "Privacy is a right, not a privilege. It’s not something to justify, but something to defend.",
      privacyAuthor: "Edward Snowden",
    },
    contact: {
      title: "Collaborate with Us",
      description:
        "Your support is essential to promoting digital awareness! By collaborating, you help us educate, expand our reach, and create a safer digital environment.",
      callToAction: "Here’s how you can contribute:",
      workshops: "Organize workshops and educational events.",
      innovativeIdeas: "Contribute innovative ideas to expand our mission.",
      brandCollaborations: "Collaborate with brands supporting digital security.",
      donations: "Make donations to maintain hosting and fund campaigns.",
      joinButton: "Join",
      formTitle: "Get in Touch",
      formSubtitle:
        "If you have ideas, projects, or collaborations in mind, feel free to send us a message.",
    },
    header: {
      home: "Home",
      about: "About Us",
      education: "Education",
      articles: "Articles",
      contact: "Contact",
      tools: "Tools",
      socialMediaTooltip: "Social Media & Podcast Links",
    },
    faq: {
      title: "Frequently Asked Questions",
      questions: [
        {
          question: "How can I protect my online accounts?",
          answer:
            "To protect your accounts, use strong passwords combining uppercase, lowercase, numbers, and symbols. Enable multi-factor authentication (MFA) whenever possible, and avoid reusing the same password across multiple accounts.",
        },
        {
          question: "What is multi-factor authentication?",
          answer:
            "Multi-factor authentication (MFA) is an extra layer of security that requires not just a password, but also a second verification factor, such as a code sent to your phone or an authentication app.",
        },
        {
          question: "What should I do if I am a victim of cyberbullying?",
          answer:
            "If you are a victim of cyberbullying, do not respond to aggressors. Save evidence of the messages or actions and block the bully. Report the incident to the platform where it occurred, and seek legal or professional help if necessary.",
        },
        {
          question:
            "What impact does excessive social media consumption have on mental health, and how can I control it?",
          answer:
            "Excessive social media consumption can negatively affect mental health, causing anxiety, stress, and unrealistic comparisons. To manage usage, set time limits, disable non-essential notifications, and engage in offline activities like reading or exercising. If this impacts your mental health, seek professional help.",
        },
      ],
    },
    tools: {
      title: "Tools",
      openPomodoro: "Open the Break Timer",
      tutorialsTitle: "Tutorials",
      tutorialDescription:
        "Explore step-by-step guides to enhance your digital skills.",
      openTutorials: "Open Tutorials",
    },
  },

  es: {
    home: {
      welcomeMessage:
        "Plataforma de recursos que busca promover y elevar la conciencia del consumo digital.",
      aboutUsTitle: "Sobre Nosotros",
      aboutUsMessage:
        "Bienvenido a Conciencia Digital. Promovemos un uso seguro de la tecnología y empoderamos a las personas con herramientas y conocimientos para protegerse de los riesgos del cibercrimen, como el ciberbullying y el grooming, y para navegar el mundo digital de manera consciente y segura.",
      initiatives: "¡Únete a nosotros y sigue nuestras iniciativas en redes sociales!",
      hashtags:
        "#AntiCiberBullying #LuchaContraElGrooming #AntiEstafasDigitales #HackersEticos",
      welcomeButton: "Ver más",
      supportButton: "Apoya",
    },
    education: {
      title: "Recursos Educativos",
      searchPlaceholder: "Buscar recursos educativos",
      loading: "Cargando recursos...",
      error: "Error al cargar los recursos educativos",
      noResults: "No se encontraron recursos.",
      digitalBreakMessage:
        "Toma un respiro y mejora tu enfoque con nuestro Digital Break Timer.",
      digitalBreakCTA: "Quiero un Break",
    },
    articles: {
      title: "Artículos Informativos",
      searchPlaceholder: "Buscar artículos",
      loading: "Cargando artículos...",
      error: "Error al cargar los artículos",
      noResults: "No se encontraron artículos.",
      privacyQuote:
        "La privacidad es un derecho, no un privilegio. No es algo que deba ser justificado, sino algo que debe ser defendido.",
      privacyAuthor: "Edward Snowden",
    },
    contact: {
      title: "Colabora con Nosotros",
      description:
        "¡Tu apoyo es esencial para promover la conciencia digital! Al colaborar, nos ayudas a educar, expandir nuestro alcance y crear un entorno digital más seguro.",
      callToAction: "Aquí tienes cómo puedes contribuir:",
      workshops: "Organizar talleres y eventos educativos.",
      innovativeIdeas: "Aportar ideas innovadoras para expandir nuestra misión.",
      brandCollaborations: "Colaborar con marcas comprometidas con la seguridad digital.",
      donations: "Hacer donaciones para mantener el hosting y financiar campañas.",
      joinButton: "Únete",
      formTitle: "Ponte en Contacto",
      formSubtitle:
        "Si tienes ideas, proyectos o colaboraciones en mente, no dudes en enviarnos un mensaje.",
    },
    header: {
      home: "Inicio",
      about: "Nosotros",
      education: "Educación",
      articles: "Artículos",
      contact: "Contacto",
      tools: "Herramientas",
      socialMediaTooltip: "Enlaces de Redes Sociales y Podcast",
    },
    faq: {
      title: "Preguntas Frecuentes",
      questions: [
        {
          question: "¿Cómo protejo mis cuentas en línea?",
          answer:
            "Para proteger tus cuentas, usa contraseñas fuertes combinando mayúsculas, minúsculas, números y símbolos. Activa la autenticación multifactor (MFA) siempre que sea posible, y evita usar la misma contraseña para múltiples cuentas.",
        },
        {
          question: "¿Qué es la autenticación multifactor?",
          answer:
            "La autenticación multifactor (MFA) es una capa adicional de seguridad que requiere no solo una contraseña, sino también un segundo factor de verificación, como un código enviado a tu teléfono o una aplicación de autenticación.",
        },
        {
          question: "¿Qué debo hacer si soy víctima de ciberbullying?",
          answer:
            "Si eres víctima de ciberbullying, no respondas a los agresores. Guarda evidencia de los mensajes o acciones de acoso y bloquea al acosador. Informa el incidente a la plataforma en la que ocurrió y, si es necesario, busca ayuda legal o profesional.",
        },
        {
          question:
            "¿Qué impacto tiene el consumo excesivo de redes sociales en la salud mental y cómo puedo controlarlo?",
          answer:
            "El consumo excesivo de redes sociales puede afectar negativamente nuestra salud mental, generando ansiedad, estrés y comparaciones poco realistas con los demás. Para controlar su uso, establece límites de tiempo, desactiva notificaciones no esenciales y realiza actividades offline que promuevan el bienestar, como leer o hacer ejercicio.",
        },
      ],
    },
    tools: {
      title: "Herramientas",
      openPomodoro: "Abrir Temporizador de Descanso",
      tutorialsTitle: "Tutoriales",
      instagramUsage: "Cómo medir tu consumo de Instagram",
      gmail2FA: "Cómo activar la verificación en 2 pasos en Gmail",
      backToTutorials: "Volver a Tutoriales",
      tutorialDescription:
        "Explora guías paso a paso para mejorar tus habilidades digitales.",
      openTutorials: "Abrir Tutoriales",
    },
  },
};

export default translations;
