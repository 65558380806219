import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton"; // Importamos Skeleton
import logo from "../assets/ConcienciaDigitalLogo1.png";
import LanguageSwitcher from "./LanguageSwitcher";
import { useLanguageStore } from "../components/utils/LanguageStore";

const Header: React.FC = () => {
  const { t } = useLanguageStore();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [logoLoaded, setLogoLoaded] = useState(false); // Controlamos si el logo se cargó

  const pages: { name: string; path: string }[] = [
    { name: t("header.home"), path: "/" },
    { name: t("header.education"), path: "/educacion" },
    { name: t("header.articles"), path: "/articulos" },
    { name: t("header.tools"), path: "/herramientas" },
    { name: t("header.contact"), path: "/contacto" },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#1871b1", boxShadow: "none" }}
      >
        <Toolbar sx={{ px: 0, minHeight: 64 }}>
          {/* Logo con Skeleton */}
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Link to="/">
              {/* Skeleton mientras el logo carga */}
              {!logoLoaded && (
                <Skeleton
                  variant="rectangular"
                  width={80} // Tamaño similar al logo
                  height={80}
                  sx={{ borderRadius: "50%" }}
                />
              )}
              {/* Logo */}
              <img
                src={logo}
                loading="eager"
                alt="Conciencia Digital Logo"
                onLoad={() => setLogoLoaded(true)} // Marca el logo como cargado
                style={{
                  height: 80,
                  width: "auto",
                  display: logoLoaded ? "block" : "none", // Oculta el logo hasta que cargue
                }}
              />
            </Link>
          </Box>

          {/* Menú responsivo */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.path}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            {pages.map((page) => (
              <Link
                key={page.name}
                to={page.path}
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    transition: "color 0.3s ease",
                    "&:hover": {
                      color: "#d1e1f0",
                      textDecoration: "underline",
                    },
                  }}
                >
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          {/* Iconos sociales */}
          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <LanguageSwitcher />
            <Tooltip title={t("header.socialMediaTooltip")}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <a
                  href="https://www.linkedin.com/company/concienciadigital/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      p: 0,
                      color: "#f0f0f0",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#10A5D1",
                      },
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </a>
                <a
                  href="https://www.youtube.com/watch?v=GSn2oU3lHhk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      p: 0,
                      color: "#f0f0f0",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#10A5D1",
                      },
                    }}
                  >
                    <PodcastsIcon />
                  </IconButton>
                </a>
              </Box>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar
        id="back-to-top-anchor"
        sx={{ minHeight: 0, bgcolor: "transparent" }}
      />
    </React.Fragment>
  );
};

export default Header;
