import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Card, CardContent, Button, CardActions, CardMedia } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Tutorial1 from "../assets/portadaInsta.png";
import Tutorial2 from "../assets/portadaGmail.png"; 

const TutorialsScreen: React.FC = () => {
  const navigate = useNavigate();

  const tutorials = [
    {
      title: "Cómo medir tu consumo de Instagram",
      description: "Aprende a medir y gestionar tu consumo en Instagram.",
      path: "/tutorials/instagram-usage",
      image: Tutorial1, 
    },
    {
      title: "Cómo activar la verificación en 2 pasos en Gmail",
      description: "Aumenta la seguridad de tu cuenta de Gmail con la verificación en 2 pasos.",
      path: "/tutorials/gmail-2fa",
      image: Tutorial2,
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: "#f9f9f9",
          minHeight: "calc(100vh - 64px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Título Principal */}
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Tutorials
        </Typography>

        {/* Contenedor de las tarjetas */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(2, 1fr)" },
            gap: 4,
            width: "100%",
          }}
        >
          {tutorials.map((tutorial, index) => (
            <Card
              key={index}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="280"
                image={tutorial.image} // Usa la imagen específica
                alt={`Imagen de ${tutorial.title}`}
              />
              <CardContent sx={{ padding: "1.5rem" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#333" }}
                >
                  {tutorial.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: "1.5rem" }}
                >
                  {tutorial.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center", paddingBottom: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(tutorial.path)}
                  sx={{
                    borderRadius: "25px",
                    padding: "0.5rem 2rem",
                    backgroundColor: "#10A5D1",
                    "&:hover": { backgroundColor: "#0d8bb5" },
                  }}
                >
                  Ver Tutorial
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default TutorialsScreen;
