import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import MediaCard from "./MediaCard";

interface GridComponentProps {
  cardData: {
    title: string;
    description: string;
    link: string;
    image: string;
  }[];
}

const GridComponent: React.FC<GridComponentProps> = ({ cardData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Box
      sx={{
        display: "grid",
        gap: "16px",
        gridTemplateColumns: isMobile
          ? "minmax(0, 300px)" // Limitar el ancho en móviles y centrar
          : isTablet
          ? "repeat(2, minmax(0, 1fr))" // 2 columnas en tablets
          : "repeat(3, minmax(0, 1fr))", // 3 columnas en pantallas grandes
        justifyContent: "center", // Centrar las columnas horizontalmente
        alignItems: "center",
        width: "100%",
        padding: "16px 0",
        margin: "0 auto", // Asegura que el contenedor esté centrado
      }}
    >
      {cardData.map((card, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center", // Centrar la card dentro de su celda
          }}
        >
          <MediaCard {...card} />
        </Box>
      ))}
    </Box>
  );
};

export default GridComponent;
